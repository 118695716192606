import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { IoIosAppstore } from "react-icons/io";
import { IoLogoGooglePlaystore } from "react-icons/io5";

function CTA() {
  return (
    <div>
      <div className=" px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="min-h-[35vh] md:min-h-[50vh] bg-cta bg-center bg-cover bg-no-repeat relative rounded-xl">
          <div className="absolute bottom-0 start-0 end-0 max-w-sm text-center mx-auto p-6 md:start-auto md:text-start md:mx-0">
            <div className="px-5 py-4 inline-block bg-white rounded-lg md:p-7">
              <div className="hidden md:block">
                <h3 className="text-lg font-bold text-gray-800 sm:text-2xl">
                  Download Vetsee Today!
                </h3>
                <p className="mt-2 text-gray-500">
                  The ultimate solution for all your pet’s healthcare and
                  grooming needs.
                </p>
              </div>

              <div className="md:mt-16">
                <div className="flex flex-col md:flex-row mt-6 md:mt-0 gap-5">
                  <Link
                    target="_blank"
                    to="https://apps.apple.com/eg/app/vetsee/id6502232464"
                  >
                    <motion.div
                      whileHover={{
                        y: -5,
                      }}
                      whileTap={{
                        scale: 1.05,
                      }}
                      className="bg-primary p-5 rounded-full font-bold flex gap-2 text-white cursor-pointer"
                    >
                      <IoIosAppstore className="text-2xl " />
                    </motion.div>
                  </Link>
                  <Link
                    to="https://play.google.com/store/apps/details?id=app.vetsee"
                    target="_blank"
                  >
                    <motion.div
                      whileHover={{
                        y: -5,
                      }}
                      whileTap={{
                        scale: 1.05,
                      }}
                      className="bg-secondary p-5 rounded-full font-bold flex gap-2 text-white cursor-pointer"
                    >
                      <IoLogoGooglePlaystore className="text-2xl" />
                    </motion.div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;

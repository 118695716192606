import { ThemeIcon } from "@mantine/core";
import React, { useEffect } from "react";
import { FaTruck, FaUserDoctor, FaWandMagicSparkles } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function Services() {
  const controls = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  const variant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  return (
    <div ref={ref} className="px-10 md:px-40 mt-10 md:mt-0 md:py-40">
      <div className="flex flex-col gap-1 text-center">
        <span className="text-xl text-primary text-center uppercase tracking-[6px] font-[600]">
          What We Do
        </span>
        <span className="text-center text-gray-400">
          We provide a range of services to help you take care of your pets.
        </span>
      </div>
      <div className="grid grid-cols-3 gap-6 md:gap-2 mt-5 px-3">
        <motion.div
          initial="hidden"
          animate={controls}
          variants={variant}
          transition={{ duration: 0.3 }}
          className="col-span-3 md:col-span-1 flex flex-col gap-4"
        >
          <div className="p-5 bg-primary w-10">
            <ThemeIcon className="!bg-secondary">
              <FaUserDoctor size={20} />
            </ThemeIcon>
          </div>
          <p className="text-2xl font-bold">Veterinary Appointments</p>
          <span className="text-gray-500">
            Find certified veterinarians near you and book medical appointments
            with ease.
          </span>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={controls}
          variants={variant}
          transition={{ delay: 0.3, duration: 0.3 }}
          className="col-span-3 md:col-span-1 flex flex-col gap-4"
        >
          <div className="p-5 bg-primary w-10">
            <ThemeIcon className="!bg-secondary">
              <FaWandMagicSparkles size={20} />
            </ThemeIcon>
          </div>
          <p className="text-2xl font-bold">Grooming Centers</p>
          <span className="text-gray-500">
            Choose from trusted pet grooming centers in your area for a full
            grooming experience.
          </span>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={controls}
          variants={variant}
          transition={{ delay: 0.6, duration: 0.3 }}
          className="col-span-3 md:col-span-1 flex flex-col gap-4"
        >
          <div className="p-5 bg-primary w-10">
            <ThemeIcon className="!bg-secondary">
              <FaTruck size={20} />
            </ThemeIcon>
          </div>{" "}
          <p className="text-2xl font-bold">Mobile Grooming</p>
          <span className="text-gray-500">
            No need to leave home! Our mobile grooming service will come to you.
          </span>
        </motion.div>
      </div>
    </div>
  );
}

export default Services;

import React from "react";
import heroImage from "../assets/images/hero.png";
import { IoIosAppstore } from "react-icons/io";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "./Header";

function Hero() {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Header />
      <div className="flex flex-col gap-5 justify-center items-center text-center w-[90%] py-10 self-center">
        <div className="flex flex-col gap-5 md:max-w-[50%]">
          <span className="text-4xl md:text-7xl font-[900] rounded-md">
            Your One-Stop App for Pet Care
          </span>
        </div>
        <span className="text-xl text-gray-500">
          Book veterinary appointments, grooming services, and mobile grooming
          with a tap!
        </span>

        <div className="flex flex-col md:flex-row mt-6 md:mt-0 gap-5">
          <Link
            target="_blank"
            to="https://apps.apple.com/eg/app/vetsee/id6502232464"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-primary p-5 rounded-full font-bold flex gap-2 text-white cursor-pointer"
            >
              <IoIosAppstore className="text-2xl " />
              Download For IOS
            </motion.div>
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=app.vetsee"
            target="_blank"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-secondary p-5 rounded-full font-bold flex gap-2 text-white cursor-pointer"
            >
              <IoLogoGooglePlaystore className="text-2xl" />
              Download For Android
            </motion.div>
          </Link>
        </div>
        <img
          src={heroImage}
          alt="hero"
          className="w-full rounded-[4rem] mt-5"
        />
      </div>
    </div>
  );
}

export default Hero;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import DeleteAccount from "./pages/DeleteAccount";
import { MantineProvider } from "@mantine/core";

function App() {
  return (
    <MantineProvider
      theme={{
        colorScheme: "light",
      }}
    >
      <Router>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="delete-account" element={<DeleteAccount />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;

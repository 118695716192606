import React from "react";
import { motion } from "framer-motion";
import { IoIosAppstore } from "react-icons/io";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { Link } from "react-router-dom";
import vetseeLogo from "../assets/images/logo.png";

function Header() {
  return (
    <div className="w-[80%] flex justify-between items-center mt-5 md:px-5 py-3 rounded-full">
      <img src={vetseeLogo} alt="Vetsee Logo" className="w-12 md:w-[100px]" />

      <div className="flex gap-2">
        <Link
          target="_blank"
          to="https://apps.apple.com/eg/app/vetsee/id6502232464"
        >
          <motion.div
            whileHover={{
              y: -5,
            }}
            whileTap={{
              scale: 1.05,
            }}
            className="bg-primary p-2 rounded-full font-bold flex gap-2 text-white cursor-pointer"
          >
            <IoIosAppstore className="text-xl " />
          </motion.div>
        </Link>
        <Link
          to="https://play.google.com/store/apps/details?id=app.vetsee"
          target="_blank"
        >
          <motion.div
            whileHover={{
              y: -5,
            }}
            whileTap={{
              scale: 1.05,
            }}
            className="bg-secondary p-2 rounded-full font-bold flex gap-2 text-white cursor-pointer"
          >
            <IoLogoGooglePlaystore className="text-xl" />
          </motion.div>
        </Link>
      </div>
    </div>
  );
}

export default Header;

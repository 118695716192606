import React from "react";
import logo from "../assets/images/logo.png";
import { IoIosAppstore } from "react-icons/io";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { motion } from "framer-motion";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaPhone,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="rouned-t-md p-6 px-[5%] w-full flex flex-col gap-5 justify-center">
      <div className="grid grid-cols-3 gap-10">
        <div className="col-span-3 md:col-span-1 flex flex-col gap-5 items-center md:items-start">
          <img src={logo} className="w-12 md:w-[100px]" alt="Vetsee logo" />
          <span className="text-sm font-semibold text-center md:text-start">
            The first 100% veterinary services platform in Egypt and the Middle
            East.
          </span>
          <div className="socials flex gap-4 items-center">
            <Link to="https://www.facebook.com/vetsee.net" target="_blank">
              <motion.div
                whileHover={{
                  y: -4,
                }}
              >
                <FaFacebook className="cursor-pointer " size={30} />
              </motion.div>
            </Link>
            <Link to="https://www.instagram.com/vetsee.app/" target="_blank">
              <motion.div
                whileHover={{
                  y: -4,
                }}
              >
                <FaInstagram className="cursor-pointer " size={30} />
              </motion.div>
            </Link>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1 flex flex-col gap-5 px-10  items-center md:items-start">
          <div className="flex flex-col md:flex-row gap-2  items-center md:items-start">
            <FaLocationArrow size={20} />
            <p className="text-md font-bold">
              53 Capital Mall, behind the court House, 2nd Floor, 5th Settlement
              New Cairo, Cairo, Egypt
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <FaPhone size={15} />
            <p className="text-md font-bold">+2 (010) 7 0026446</p>
          </div>
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <FaEnvelope size={15} />
            <p className="text-md font-bold"> info@vetsee.net</p>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1 flex flex-col gap-2">
          <Link
            target="_blank"
            to="https://apps.apple.com/eg/app/vetsee/id6502232464"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-gray-50 p-5 rounded-full font-bold cursor-pointer flex gap-1 items-center"
            >
              <IoIosAppstore className="text-2xl  text-primary" />
              Download For IOS
            </motion.div>
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=app.vetsee"
            target="_blank"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-gray-50 p-5 rounded-full font-bold cursor-pointer flex gap-1 items-center"
            >
              <IoLogoGooglePlaystore className="text-2xl text-secondary" />
              Download For Android
            </motion.div>
          </Link>
        </div>
      </div>
      <hr className="" />
      <small className="font-bold text-gray-500">
        &#169; Vetsee 2024 - All Rights Reserved
      </small>
    </div>
  );
}

export default Footer;

import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import Services from "../components/Services";
import WhyUs from "../components/WhyUs";
import CTA from "../components/CTA";

function Home() {
  return (
    <div className="flex flex-col w-full justify-center">
      <Hero />
      <HowItWorks />
      <Services />
      <WhyUs />
      <CTA />
      <Footer />
    </div>
  );
}

export default Home;

import { ThemeIcon } from "@mantine/core";
import React, { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaBoltLightning, FaTruck, FaUserDoctor } from "react-icons/fa6";
import whyUsImage from "../assets/images/why-vetsee.jpg";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function WhyUs() {
  const controls = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  const variant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  return (
    <div ref={ref} className="md:px-40 mt-10 md:mt-0 md:py-40">
      <div className="w-full text-center">
        <span className="text-xl text-primary text-center uppercase tracking-[6px] font-[600]">
          Why Choose Vetsee
        </span>
      </div>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
          <div className="lg:col-span-7">
            <div className="grid grid-cols-12 gap-2 sm:gap-6 items-center lg:-translate-x-10">
              <div className="col-span-12">
                <motion.img
                  initial="hidden"
                  animate={controls}
                  variants={variant}
                  transition={{ duration: 0.4 }}
                  className="rounded-xl"
                  src={whyUsImage}
                  alt="Features"
                />
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-10 lg:mt-0 lg:col-span-5">
            <motion.div
              initial="hidden"
              animate={controls}
              variants={variant}
              transition={{ delay: 0.4, duration: 0.4 }}
              className="space-y-6 sm:space-y-8"
            >
              <div className="space-y-2 md:space-y-4">
                <h2 className="font-bold text-3xl lg:text-4xl">
                  Why Vetsee is the Ultimate Pet Care Solution
                </h2>
                <p className="text-gray-500">
                  Simplifying pet care, one appointment at a time.
                </p>
              </div>

              <ul className="space-y-2 sm:space-y-4">
                <li className="flex gap-x-3">
                  <ThemeIcon>
                    <FaBoltLightning />
                  </ThemeIcon>
                  <div className="grow">
                    <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                      <span className="font-bold">Convenience</span> – Book
                      anytime, anywhere.
                    </span>
                  </div>
                </li>

                <li className="flex gap-x-3">
                  <ThemeIcon>
                    <FaUserDoctor />
                  </ThemeIcon>
                  <div className="grow">
                    <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                      <span className="font-bold">Trusted Professionals</span> –
                      Certified veterinarians and groomers.
                    </span>
                  </div>
                </li>

                <li className="flex gap-x-3">
                  <ThemeIcon>
                    <FaCheckCircle />
                  </ThemeIcon>
                  <div className="grow">
                    <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                      <span className="font-bold">All-in-One</span> – Manage pet
                      profiles, appointments, and health records in one app.
                    </span>
                  </div>
                </li>

                <li className="flex gap-x-3">
                  <ThemeIcon>
                    <FaTruck />
                  </ThemeIcon>
                  <div className="grow">
                    <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                      <span className="font-bold">Mobile Grooming</span> – We
                      come to your home for stress-free pet care.
                    </span>
                  </div>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;

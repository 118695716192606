import React, { useEffect } from "react";
import { Card, Group, Image } from "@mantine/core";
import featureOne from "../assets/images/feature-one.png";
import featureTwo from "../assets/images/feature-two.png";
import featureThree from "../assets/images/feature-three.png";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

function HowItWorks() {
  const controls = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  const cardVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  return (
    <div ref={ref} className="px-10 md:px-40 mt-10 md:mt-0 md:py-40">
      <div className="grid grid-cols-3 gap-5">
        <motion.div
          initial="hidden"
          animate={controls}
          variants={cardVariant}
          transition={{ duration: 0.3 }}
          className="col-span-3 md:col-span-1 h-full"
        >
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            className="h-full"
          >
            <Card.Section>
              <Image src={featureOne} height={160} alt="add pet" />
            </Card.Section>

            <Group justify="space-between" mt="md" mb="xs">
              <span className="font-semibold">Step 1: Add Your Pet</span>
            </Group>

            <p className="text-gray-500">
              Create a profile for each of your pets with all their essential
              details.
            </p>
          </Card>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={controls}
          variants={cardVariant}
          transition={{ delay: 0.3, duration: 0.3 }}
          className="col-span-3 md:col-span-1 h-full"
        >
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            className="h-full"
          >
            <Card.Section>
              <Image src={featureTwo} height={160} alt="book appointments" />
            </Card.Section>

            <Group justify="space-between" mt="md" mb="xs">
              <span className="font-semibold">
                Step 2: Book Appointments or Grooming Services
              </span>
            </Group>

            <p className="text-gray-500">
              Choose from medical appointments, grooming centers, or our mobile
              grooming service.
            </p>
          </Card>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={controls}
          variants={cardVariant}
          transition={{ delay: 0.6, duration: 0.3 }}
          className="col-span-3 md:col-span-1 h-full"
        >
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            className="h-full"
          >
            <Card.Section>
              <Image src={featureThree} height={160} alt="track pet health" />
            </Card.Section>

            <Group justify="space-between" mt="md" mb="xs">
              <span className="font-semibold">
                Step 3: Track Your Pet's Health
              </span>
            </Group>

            <p className="text-gray-500">
              Keep track of your pet’s appointment history and health timeline.
            </p>
          </Card>
        </motion.div>
      </div>
    </div>
  );
}

export default HowItWorks;
